<script lang="ts" setup>
interface propTypes {
  isLight?: boolean
  as?: string
}

const props = withDefaults(defineProps<propTypes>(), {
  isLight: false,
  as: 'section',
})
</script>

<template>
  <Component :is="props.as" :data-block-invert="props.isLight">
    <slot />
  </Component>
</template>

<style>

</style>
